/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useDebounce from "utils/hooks";
import { Icon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "components/MDDialog/ConfirmationDialog";
import { deleteCategory } from "redux/actions/categoryAction";
import MDButton from "components/MDButton";
import { getService } from "redux/actions/serviceAction";
import Constants from "services/constant";
import { Delete } from "redux/actions/serviceAction";

function Services() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const searchKeys = ["fullName", "email"];
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useDebounce(500, "");
  const [filterKey, setFilterKey] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const deleteDialogText = "Are you sure ypu want to delete this category?";
  const [rowData, setRowData] = useState({});

  useEffect(() => {
    dispatch(getService(setLoading, filterData, searchKeys, search, "", currentPage, rows));
  }, [dispatch, currentPage, rows, searchText, filterData]);

  const allServices = useSelector((state) => state.getservice.allServices);

  const columns = [
    { Header: "Name", accessor: "name", align: "left" },
    { Header: "Description", accessor: "description", align: "left" },
    {
      Header: "Icon",
      accessor: "icon",
      align: "center",
      Cell: (props) => {
        return <img width={50} src={Constants.base_url + props.cell.value} />;
      },
    },
    {
      Header: "Actions",
      accessor: "action",
      align: "center",
      Cell: (props) => {
        return (
          <>
            <Icon
              sx={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={() => navigate(`/services/view/${props.row.original._id}`)}
            >
              visibility
            </Icon>
            <Icon
              sx={{ fontWeight: "bold", cursor: "pointer", marginX: "5px" }}
              onClick={() => navigate(`/services/edit/${props.row.original._id}`)}
            >
              edit
            </Icon>
            <Icon
              sx={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={() => {
                setRowData(props.row.original);
                setShowConfirm(true);
              }}
            >
              delete
            </Icon>
          </>
        );
      },
    },
  ];

  const onConfirmDelete = () => {
    dispatch(
      Delete(
        rowData._id,
        setShowConfirm,
        currentPage,
        rows,
        searchKeys,
        search,
        filterKey,
        filterData
      )
    );
  };

  return (
    <DashboardLayout>
      <ConfirmDialog
        open={showConfirm}
        setOpen={setShowConfirm}
        dialogText={deleteDialogText}
        handleAgree={onConfirmDelete}
      />

      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                  <MDTypography variant="h6" color="white">
                    Services Table
                  </MDTypography>
                  <MDButton
                    variant="gradient"
                    color="light"
                    onClick={() => navigate(`/services/add`)}
                  >
                    Add Services
                  </MDButton>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: allServices && allServices.services.length ? allServices.services : [] }}
                  entriesPerPage={false}
                  showTotalEntries={true}
                  totalCount={allServices.count}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  limit={rows}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Services;
