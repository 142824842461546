export const types = {
  SHOW_TOAST: "SHOW_TOAST",
  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  SHOW_SIDEBAR_LOADER: "SHOW_SIDEBAR_LOADER",
  HIDE_SIDEBAR_LOADER: "HIDE_SIDEBAR_LOADER",
  USERS: "USERS",
  USER_DETAIL: "USER_DETAIL",
  CATEGORIES: "CATEGORIES",
  GETSERVICES: "GETSERVICES",
  ADMIN: "ADMIN",
};
