import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { isAuthenticated } from "services/auth";
import routes from "routes";

export default function ProtectedRoute() {
    const getAuthRoutes = (appRoutes) => {
        return appRoutes.map((prop, key) => {
            return (
                <Route
                    exact
                    path={prop.route}
                    key={prop.key}
                    element={isAuthenticated() ? prop.component : <Navigate to="/authentication/sign-in" />}
                />
            )
        });
    };

    return (
        <Routes>
            {getAuthRoutes(routes)}
        </Routes>
    );
}