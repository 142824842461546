import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";

const getCategories =
  (
    setLoading,
    page = "",
    limit = "",
    searchKeys = "",
    search = "",
    filterKey = "",
    filterData = ""
  ) =>
  async (dispatch) => {
    if (setLoading) {
      setLoading(true);
    }
    const res =
      page === "ALL"
        ? await api("get", Constants.END_POINT.CATEGORIES)
        : await api(
            "get",
            `${
              Constants.END_POINT.CATEGORIES
            }?page=${page}&limit=${limit}&searchKeys=${JSON.stringify(
              searchKeys
            )}&searchString=${search}&filterKey=${filterKey}&filterData=${JSON.stringify(
              filterData
            )}`
          );
    if (res.success) {
      if (res.data) {
        dispatch({
          type: types.CATEGORIES,
          payload: res.data,
          count: res.count,
        });
      }
    } else {
      dispatch({
        type: types.CATEGORIES,
        payload: [],
        count: 0,
      });
    }
    if (setLoading) {
      setLoading(false);
    }
  };

const getCategoyWithQuestions = (id, returnData) => async (dispatch) => {
  const res = await api("get", Constants.END_POINT.CATEGORIES_WITH_QUESTIONS + id);
  console.log("res", res);
  if (res.success) {
    if (res.data) {
      if (returnData) {
        returnData(res.data);
      }
    }
  } else {
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

const createCategory = (payload, navigate) => async (dispatch) => {
  const res = await api("post", Constants.END_POINT.CATEGORIES, payload);
  if (res.success) {
    navigate(-1);
  }
};

const deleteCategory =
  (id, next, page, limit, searchKeys, search, filterKey, filterData) => async (dispatch) => {
    const res = await api("put", Constants.END_POINT.DELETE_CATEGORY + id);
    if (res.success) {
      dispatch(getCategories(null, page, limit, searchKeys, search, filterKey, filterData));
      next(false);
    }
  };

const getQuestion = (id, returnData) => async (dispatch) => {
  const res = await api("get", Constants.END_POINT.GET_QUESTION + id);
  if (res.success) {
    if (res.data) {
      if (returnData) {
        returnData(res.data);
      }
    }
  } else {
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

const updateCategory = (id, payload, navigate) => async (dispatch) => {
  const res = await api("put", Constants.END_POINT.UPDATE_CATEGORY + id, payload);
  if (res.success) {
    navigate(-1);
  }
};

const createQuestion = (payload, navigate) => async (dispatch) => {
  const res = await api("post", Constants.END_POINT.ADD_QUESTION, payload);
  if (res.success) {
    navigate(-1);
  }
};

const updateQuestion = (id, payload, navigate) => async (dispatch) => {
  const res = await api("put", Constants.END_POINT.UPDATE_QUESTION + id, payload);
  if (res.success) {
    navigate(-1);
  }
};

const deleteQuestion = (id, categoryId, returnData, next) => async (dispatch) => {
  const res = await api("put", Constants.END_POINT.DELETE_QUESTION + id);
  if (res.success) {
    dispatch(getCategoyWithQuestions(categoryId, returnData));
    next(false);
  }
};

const uploadFile = (file, returnData) => async (dispatch) => {
  const formData = new FormData();
  formData.append("file", file, file.name);
  const res = await api("post", Constants.END_POINT.UPLOAD_FILE, formData);
  if (res.success) {
    if (res.data) {
      if (returnData) {
        returnData(res.data.path);
      }
    }
  } else {
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export {
  getCategories,
  deleteCategory,
  getCategoyWithQuestions,
  getQuestion,
  updateCategory,
  updateQuestion,
  deleteQuestion,
  createQuestion,
  createCategory,
  uploadFile,
};
