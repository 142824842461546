import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Snackbar } from "@mui/material";

export default function ToastContainer() {

    const { toastInfo } = useSelector((state) => state.toast);
    const [showToast, setShowTaost] = useState(true);

    useEffect(() => {
        setShowTaost(toastInfo.open)
    }, [toastInfo]);

    return (
        <Snackbar
            open={showToast}
            autoHideDuration={3000}
            onClose={() => setShowTaost(false)}
            message={toastInfo.message}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />
    );
}