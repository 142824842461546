import React, { useEffect, useState } from "react";
import { Card, Divider, Grid, MenuItem } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useParams } from "react-router-dom";
import ServicesContainer from "./ServicesContainer";
import ConfirmDialog from "components/MDDialog/ConfirmationDialog";
import DropDown from "components/MDDropDown/DropDown";
import { getCategories } from "redux/actions/categoryAction";
import { useDispatch, useSelector } from "react-redux";

import Constants from "services/constant";

const ServicesForm = ({ type }) => {
  const dispatch = useDispatch();
  const {
    data,
    handleChange,
    onSubmit,
    setFormType,
    navigate,
    showConfirm,
    setShowConfirm,
    deleteDialogText,
    onConfirmDelete,
    handleFileChange,
    file,
  } = ServicesContainer();

  useEffect(() => {
    setFormType(type);
  }, [type, setFormType]);

  useEffect(() => {
    dispatch(getCategories(null, "ALL"));
  }, []);

  let allCategories = useSelector((state) => state.categories.allCategories);

  return (
    <>
      <ConfirmDialog
        open={showConfirm}
        setOpen={setShowConfirm}
        dialogText={deleteDialogText}
        handleAgree={onConfirmDelete}
      />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Services Details
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={6}>
                    <MDInput
                      type="text"
                      label="Name"
                      fullWidth
                      name="name"
                      disabled={type === "VIEW"}
                      value={data?.name}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={6} py={4}>
                  <DropDown
                    type="text"
                    label="category"
                    fullWidth
                    name="category"
                    disabled={type === "VIEW"}
                    value={data?.category}
                    onChange={handleChange}
                    options={allCategories?.categories?.map((category) => ({
                      name: category?.name,
                      value: category?._id,
                    }))}
                  />
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Description"
                      fullWidth
                      disabled={type === "VIEW"}
                      value={data?.description}
                      name={"description"}
                      onChange={handleChange}
                    />
                  </MDBox>
                </Grid>
              </MDBox>
              <Grid item xs={12} md={12} lg={4} mb={4}>
                <MDTypography variant="h6" color="black">
                  Icon
                </MDTypography>
                <MDBox mb={2}>
                  <img
                    src={file ? `${data.icon}` : data.icon ? Constants.base_url + data.icon : ""}
                    width="50px"
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="file"
                    fullWidth
                    disabled={type === "VIEW"}
                    onChange={handleFileChange}
                  />
                </MDBox>
              </Grid>

              <Grid container spacing={0} justifyContent="flex-end">
                {type !== "VIEW" ? (
                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="info" onClick={onSubmit}>
                      {type === "ADD" ? "Save" : "Update"}
                    </MDButton>
                  </MDBox>
                ) : null}
                <MDBox mt={4} mb={1} ml={1}>
                  <MDButton variant="outlined" color="info" onClick={() => navigate(-1)}>
                    Cancel
                  </MDButton>
                </MDBox>
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ServicesForm;
