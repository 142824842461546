import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import React from "react";
import UserForm from "./UserForm";

export default function ViewUser() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <UserForm type="VIEW" />
      </MDBox>
    </DashboardLayout>
  );
}
