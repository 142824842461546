import { types } from "../types/types";
const intitalState = {
  allServices: {
    services: [],
    count: 0,
  },
};

const getserviceReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.GETSERVICES:
      return {
        ...state,
        allServices: { services: action.payload, count: action.count },
      };

    default:
      return { ...state };
  }
};

export default getserviceReducer;
