import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { createService } from "redux/actions/serviceAction";
import { ViewSerives } from "redux/actions/serviceAction";
import { updateServices } from "redux/actions/serviceAction";
import { Delete } from "@mui/icons-material";

export default function ServicesContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({
    category: "",
    description: "",
    icon: "",
    name: "",
  });

  const ignoreFields = ["color"];
  const [rowData, setRowData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [formType, setFormType] = useState("EDIT");
  const [showConfirm, setShowConfirm] = useState(false);
  const deleteDialogText = "Are you sure ypu want to delete this question?";
  const [file, setFile] = useState();

  const onConfirmDelete = () => {
    dispatch(Delete(rowData._id, id, setData, setShowConfirm));
  };
  const formData = new FormData();
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "category") setData({ ...data, [name]: e.value });
    if (formType !== "VIEW") {
      const formErrors = allValidations(name, value, data);
      setData((prev) => ({ ...prev, [name]: value, formErrors }));
    }
  };

  const onSubmit = () => {
    if (showFormErrors(data, setData, ignoreFields)) {
      if (formType === "ADD") {
        formData.append("name", data.name);
        formData.append("category", data.category);
        formData.append("description", data.description);
        formData.append("icon", file);
        dispatch(createService(formData, navigate));
      } else if (formType === "EDIT") {
        formData.append("name", data.name);
        formData.append("category", data.category);
        formData.append("description", data.description);
        formData.append("icon", file ? file : data.icon);
        dispatch(updateServices(id, formData, navigate));
      }
    }
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    setData((prev) => ({ ...prev, icon: URL.createObjectURL(e.target.files[0]) }));
  };

  useEffect(() => {
    if (id) {
      dispatch(
        ViewSerives(id, (data) => {
          setData({ ...data, category: data.category._id });
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);
  return {
    data,
    handleChange,
    loading,
    onSubmit,
    setFormType,
    navigate,
    currentPage,
    setCurrentPage,
    showConfirm,
    setShowConfirm,
    deleteDialogText,
    onConfirmDelete,
    handleFileChange,
    file,
  };
}
