import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { Icon, List, ListItem } from "@mui/material";
import { getCategoyWithQuestions } from "redux/actions/categoryAction";
import { updateCategory } from "redux/actions/categoryAction";
import { deleteQuestion } from "redux/actions/categoryAction";
import { createCategory } from "redux/actions/categoryAction";
import { getService } from "redux/actions/serviceAction";

export default function CategoryContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  let allServices = useSelector((state) => state.getservice.allServices.services);

  const [data, setData] = useState({
    name: "",
    description: "",
    icon: "",
    questions: [],
    color: "",
    lowScoreSuggestion: "",
    howToImprove: {
      ourSuggestions: [
        {
          suggestionIcon: "",
          title: "",
          description: ""
        }
      ],
      topThreeServices: [],
      otherCompanySuggestions: [
        {
          title: "",
          description: ""
        }
      ]
    }
  });

  const ignoreFields = ["color"];
  const [rowData, setRowData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const [formType, setFormType] = useState("EDIT");
  const [showConfirm, setShowConfirm] = useState(false);
  const deleteDialogText = "Are you sure ypu want to delete this question?";
  const [file, setFile] = useState();

  const onConfirmDelete = () => {
    dispatch(deleteQuestion(rowData._id, id, setData, setShowConfirm));
  };
  const formData = new FormData();

  const questionsColumns = [
    { Header: "Question", accessor: "question", align: "left" },
    {
      Header: "Options",
      accessor: "choices",
      align: "left",
      Cell: (props) => {
        return (
          <List>
            {props.row.original.choices.map((choice, index) => {
              return (
                <ListItem key={choice._id}>
                  {index + 1}. {choice.name}
                </ListItem>
              );
            })}
          </List>
        );
      },
    },
    {
      Header: "Actions",
      accessor: "action",
      align: "center",
      isVisible: false,
      Cell: (props) => {
        return (
          <>
            {formType === "EDIT" ? (
              <>
                <Icon
                  sx={{ fontWeight: "bold", cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/categories/edit/${id}/question/view/${props.row.original._id}`)
                  }
                >
                  visibility
                </Icon>
                <Icon
                  sx={{ fontWeight: "bold", cursor: "pointer", marginX: "5px" }}
                  onClick={() =>
                    navigate(`/categories/edit/${id}/question/edit/${props.row.original._id}`)
                  }
                >
                  edit
                </Icon>
                <Icon
                  sx={{ fontWeight: "bold", cursor: "pointer" }}
                  onClick={() => {
                    setRowData(props.row.original);
                    setShowConfirm(true);
                  }}
                >
                  delete
                </Icon>
              </>
            ) : (
              <Icon
                sx={{ fontWeight: "bold", cursor: "pointer" }}
                onClick={() =>
                  navigate(`/categories/edit/${id}/question/view/${props.row.original._id}`)
                }
              >
                visibility
              </Icon>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (id) {
      dispatch(
        getCategoyWithQuestions(id, (res) => {
          setData({ ...data, ...res });
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (formType !== "VIEW") {
      const formErrors = allValidations(name, value, data);
      setData((prev) => ({ ...prev, [name]: value, formErrors }));
    }
  };

  const onSubmit = () => {
    if (showFormErrors(data, setData, ignoreFields)) {
      if (formType === "ADD") {
        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("icon", file);
        formData.append("questions", data.questions);
        formData.append("color", data.color);
        formData.append("lowScoreSuggestion", data.lowScoreSuggestion);
        formData.append("howToImprove", data.howToImprove);
        dispatch(createCategory(formData, navigate));
      } else if (formType === "EDIT") {
        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("icon", file ? file : data.icon);
        formData.append("questions", data.questions);
        formData.append("color", data.color);
        formData.append("lowScoreSuggestion", data.lowScoreSuggestion);
        formData.append("howToImprove", JSON.stringify(data.howToImprove));
        dispatch(updateCategory(id, formData, navigate));
      }
    }
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    setData((prev) => ({ ...prev, icon: URL.createObjectURL(e.target.files[0]) }));
  };

  useEffect(() => {
    dispatch(getService(setLoading));
  }, [dispatch]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  return {
    data,
    handleChange,
    loading,
    onSubmit,
    setFormType,
    navigate,
    questionsColumns,
    currentPage,
    setCurrentPage,
    showConfirm,
    setShowConfirm,
    deleteDialogText,
    onConfirmDelete,
    handleFileChange,
    file,
    allServices,
    setData,
    dispatch,
    MenuProps,
    getStyles
  };
}
