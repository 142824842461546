import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";

const getService =
  (setLoading, filterData = "", searchKeys = "", searchString = "", category = "", page = 1, rows = 100) =>
    async (dispatch) => {
      if (setLoading) {
        setLoading(true);
      }
      const res =
        page === "ALL"
          ? await api("get", Constants.END_POINT.GETSERVICES)
          : await api(
            "get",
            `${Constants.END_POINT.GETSERVICES
            }?page=${page}&limit=${rows}&category=${category}&searchKeys=${JSON.stringify(
              searchKeys
            )}&searchString=${searchString}&filterData=${JSON.stringify(filterData)}`
          );
      if (res.success) {
        if (res.data) {
          dispatch({
            type: types.GETSERVICES,
            payload: res.data,
            count: res.count,
          });
        }
      } else {
        dispatch({
          type: types.GETSERVICES,
          payload: [],
          count: 0,
        });
      }
      if (setLoading) {
        setLoading(false);
      }
    };

const ViewSerives = (id, returnData) => async (dispatch) => {
  const res = await api("get", Constants.END_POINT.SERVICE + id);
  if (res.success) {
    if (res.data) {
      if (returnData) {
        returnData(res.data);
      }
    }
  } else {
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

const createService = (payload, navigate) => async (dispatch) => {
  const res = await api("post", Constants.END_POINT.SERVICE, payload);
  if (res.success) {
    navigate(-1);
  }
};

const updateServices = (id, payload, navigate) => async (dispatch) => {
  const res = await api("put", Constants.END_POINT.GETSERVICES + id, payload);
  if (res.success) {
    navigate(-1);
  }
};

const Delete = (id, setShowConfirm) => async (dispatch) => {
  const res = await api("delete", Constants.END_POINT.GETSERVICES + id);
  if (res.success) {
    setShowConfirm(false);
    dispatch(getService());
    console.log("success");
  }
};

export { createService, getService, ViewSerives, updateServices, Delete };
