import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import React from "react";
import ServicesForm from "./ServicesForm";

const AddServices = () => {
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <ServicesForm type="ADD" />
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default AddServices;
