import { combineReducers } from "redux";
import loaderReducer from "./loaderReducer";
import toastReducer from "./toastReducer";
import dashboardReducer from "./dashboardReducer";
import usersReducer from "./userReducer";
import categoriesReducer from "./categoriesReducer";
import getserviceReducer from "./serviceReducer";

export default combineReducers({
  toast: toastReducer,
  loader: loaderReducer,
  dashboard: dashboardReducer,
  users: usersReducer,
  categories: categoriesReducer,
  getservice: getserviceReducer,
});
