import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateQuestion } from "redux/actions/categoryAction";
import { createQuestion } from "redux/actions/categoryAction";
import { getQuestion } from "redux/actions/categoryAction";
import { showFormErrors } from "utils/commonFunctions";
import { allValidations } from "utils/formValidations";

export default function QuestionContainer() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id, questionId } = useParams();

    const [data, setData] = useState({
        question: '',
        choices: [],
        category: id
    });
    const ignoreFields = [];

    useEffect(() => {
        if (questionId) {
            dispatch(
                getQuestion(questionId, (data) => {
                    setData({ ...data });
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionId, dispatch]);

    const [formType, setFormType] = useState("EDIT");
    const [loading, setLoading] = useState(false);

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        if (formType !== "VIEW") {
            if (index >= 0) {
                const formErrors = allValidations(name, value, data);
                const choices = [...data.choices];
                choices[index] = { ...choices[index], [name]: value }
                setData({ ...data, choices: [...choices], formErrors });
            } else {
                const formErrors = allValidations(name, value, data);
                setData((prev) => ({ ...prev, [name]: value, formErrors }));
            }
        }
    };

    const onSubmit = () => {
        if (showFormErrors(data, setData, ignoreFields)) {
            if (formType === "ADD") {
                dispatch(createQuestion(data, navigate));
            } else if (formType === "EDIT") {
                dispatch(updateQuestion(questionId, data, navigate));
            }
        }
    };

    const onAddOption = () => {
        const choices = data?.choices.push({
            name: '',
            value: ''
        })
        setData({ ...data, ...choices })
    }

    const onDeleteOption = (index) => {
        const choices = data?.choices.splice(index, 1)
        setData({ ...data, ...choices })
    }

    return { data, handleChange, loading, onSubmit, setFormType, navigate, onAddOption, onDeleteOption };
}
