import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import React from "react";
import CategoryForm from "./CategoryForm";

export default function EditCategory() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <CategoryForm type="VIEW" />
      </MDBox>
    </DashboardLayout>
  );
}
