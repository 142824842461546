export default class Constants {

  static END_POINT = {
    LOGIN: "/login",
    PROFILE: "/profile",
    FORGET_PASSWORD: "/forgot-password",
    UPDATE_PROFILE: "/update",
    RESET_PASSWORD: "/reset-password",
    UPLOAD_FILE: "/file-upload",
    CHANGE_PASSWORD: "/change-password",
    // ADMIN //
    ADMIN: "",
    UPDATE_ADMIN: "/update",
    //Dashborad
    GET_USERS_COUNT: "/users/count",
    //Users
    USERS: "/users/",
    USER: "/user/",
    ENABLE_DISABLE_USER: "/users/update-status/",

    SURVEY_EXCEL: "/survey/excel/",

    CATEGORIES: "/categories",
    CATEGORIES_WITH_QUESTIONS: "/categories/",
    UPDATE_CATEGORY: "/categories/update/",
    DELETE_CATEGORY: "/categories/delete/",

    //Service//
    SERVICE: "/services/",
    GETSERVICES: "/services/",
    // QUESTIONS //
    ADD_QUESTION: "/questions/create",
    GET_QUESTION: "/questions/",
    UPDATE_QUESTION: "/questions/update/",
    DELETE_QUESTION: "/questions/delete/",
    UPLOAD_PHOTOS: "/api/user/upload-profile-image",
  };
}
