/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";

// @mui icons
import Icon from "@mui/material/Icon";
import Users from "pages/users";
import Category from "pages/categories";
import ViewUser from "pages/users/ViewUser";
import ViewCategory from "pages/categories/ViewCategory";
import EditCategory from "pages/categories/EditCategory";
import ViewQuestion from "pages/categories/Questions/ViewQuestion";
import EditQuestion from "pages/categories/Questions/EditQuestion";
import AddQuestion from "pages/categories/Questions/AddQuestion";
import AddCategory from "pages/categories/AddCategory";
import Services from "pages/Services";
import AddServices from "pages/Services/AddServices";
import ViewServices from "pages/Services/ViewServices";
import EditServices from "pages/Services/EditServies";
import EditUser from "pages/users/EditUser";

const routes = [
  {
    showOnSideNav: true,
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    showOnSideNav: true,
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">people_alt</Icon>,
    route: "/users",
    component: <Users />,
  },
  {
    showOnSideNav: true,
    name: "Services",
    key: "services",
    icon: <Icon fontSize="small">medical_information</Icon>,
    route: "/services",
    component: <Services />,
  },
  {
    showOnSideNav: false,
    name: "Services",
    key: "Services",
    route: "/services/add",
    component: <AddServices />,
  },
  {
    showOnSideNav: false,
    name: "User",
    key: "user",
    route: "/users/view/:id",
    component: <ViewUser />,
  },
  {
    showOnSideNav: false,
    name: "User",
    key: "user",
    route: "/users/edit/:id",
    component: <EditUser />,
  },
  {
    showOnSideNav: true,
    name: "Categories",
    key: "categories",
    icon: <Icon fontSize="small">category_icon</Icon>,
    route: "/categories",
    component: <Category />,
  },
  {
    showOnSideNav: false,
    name: "Category",
    key: "category",
    route: "/categories/view/:id",
    component: <ViewCategory />,
  },
  {
    showOnSideNav: false,
    name: "Services",
    key: "Services",
    route: "/services/view/:id",
    component: <ViewServices />,
  },
  {
    showOnSideNav: false,
    name: "Service",
    key: "service",
    route: "/services/edit/:id",
    component: <EditServices />,
  },
  {
    showOnSideNav: false,
    name: "Service",
    key: "service",
    route: "/categories/add",
    component: <AddCategory />,
  },
  {
    showOnSideNav: false,
    name: "Category",
    key: "category",
    route: "/categories/edit/:id",
    component: <EditCategory />,
  },
  {
    showOnSideNav: false,
    name: "Question",
    key: "question",
    route: "/categories/edit/:id/question/add",
    component: <AddQuestion />,
  },
  {
    showOnSideNav: false,
    name: "Question",
    key: "question",
    route: "/categories/edit/:id/question/view/:questionId",
    component: <ViewQuestion />,
  },
  {
    showOnSideNav: false,
    name: "Question",
    key: "question",
    route: "/categories/edit/:id/question/edit/:questionId",
    component: <EditQuestion />,
  },
  // {
  //   showOnSideNav: true,
  //   name: "Focus Area",
  //   key: "focus-area",
  //   icon: <Icon fontSize="small">crisis_alert</Icon>,
  //   route: "/focus-area",
  //   component: <FocusArea />,
  // },
];

export default routes;
