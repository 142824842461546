import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { editUser, getUser } from "../../redux/actions/userAction";

export default function UserContainer() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();

    const [data, setData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        numberOfEmployees: 0,
        numberOfSurveys: 0,
    });
    const ignoreFields = [];

    useEffect(() => {
        if (id) {
            dispatch(
                getUser(id, (data) => {
                    setData({ ...data });
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch]);

    const [formType, setFormType] = useState("EDIT");
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (formType !== "VIEW") {
            const formErrors = allValidations(name, value, data);
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const onSubmit = () => {
        if (showFormErrors(data, setData, ignoreFields)) {
            if (formType === "EDIT") {
                // dispatch(editUser(id, data, setLoading, history));
                dispatch(editUser(id, data, navigate));

            }
        }
    };

    return { data, handleChange, loading, onSubmit, setFormType, navigate };
}
