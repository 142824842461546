import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Card, Chip, Divider, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Tab, Tabs, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CategoryContainer from "./CategoryContainer";
import { BACKEND_URL } from "constants";
import DataTable from "examples/Tables/DataTable";
import { Link, useParams } from "react-router-dom";
import ConfirmDialog from "components/MDDialog/ConfirmationDialog";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';
import { uploadFile } from "redux/actions/categoryAction";
import { useTheme } from "@emotion/react";

const CategoryForm = ({ type }) => {
  let {
    data,
    setData,
    handleChange,
    loading,
    onSubmit,
    setFormType,
    navigate,
    questionsColumns,
    currentPage,
    setCurrentPage,
    showConfirm,
    setShowConfirm,
    deleteDialogText,
    onConfirmDelete,
    handleFileChange,
    file,
    allServices,
    dispatch,
    MenuProps,
    getStyles
  } = CategoryContainer();

  const { id } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const addSuggestions = () => {
    const suggestion = {
      icon: "",
      title: "",
      description: ""
    }

    setData({
      ...data, howToImprove: {
        ...data.howToImprove,
        ourSuggestions: data?.howToImprove?.ourSuggestions ? [...data?.howToImprove?.ourSuggestions, suggestion] : [suggestion]
      }
    })
  }

  const addOtherCompanySuggestions = () => {
    const suggestion = {
      title: "",
      description: ""
    }

    setData({
      ...data, howToImprove: {
        ...data.howToImprove,
        otherCompanySuggestions: data?.howToImprove?.otherCompanySuggestions ? [...data?.howToImprove?.otherCompanySuggestions, suggestion] : [suggestion]
      }
    })
  }

  const handleIconChange = (e, index) => {
    e.preventDefault();
    dispatch(uploadFile(e.target.files[0], (res) => {
      const suggestions = [...data?.howToImprove?.ourSuggestions];
      suggestions[index].suggestionIcon = res;

      setData({
        ...data, howToImprove: {
          ...data.howToImprove,
          ourSuggestions: [...suggestions]
        }
      })
    }))
  }

  const onDeleteSuggestion = (index, field) => {
    const newSuggestions = [...data?.howToImprove[field]];
    // Remove the item at the specified index
    newSuggestions.splice(index, 1);

    setData({
      ...data, howToImprove: {
        ...data.howToImprove,
        [field]: [...newSuggestions]
      }
    })
  }

  const handleHowToImproveChange = (e, field, index) => {
    let dataClone = data?.howToImprove[field] ? [...data?.howToImprove[field]] : [];

    if (field === 'topThreeServices') {
      dataClone = [...e];
    } else {
      const { name, value } = e.target;
      if (index > -1) {
        dataClone[index][name] = value;
      }
    }

    setData({
      ...data, howToImprove: {
        ...data.howToImprove,
        [field]: [...dataClone]
      }
    })
  }

  useEffect(() => {
    setFormType(type);
  }, [type, setFormType]);

  useEffect(() => {
    if (!data?.howToImprove?.otherCompanySuggestions?.length) {
      addOtherCompanySuggestions();
    }
    if (!data?.howToImprove?.ourSuggestions?.length) {
      addSuggestions();
    }
  })

  const handleDelete = (value) => {
    console.info('You clicked the delete icon.', value);
  };

  return (
    <>
      <ConfirmDialog
        open={showConfirm}
        setOpen={setShowConfirm}
        dialogText={deleteDialogText}
        handleAgree={onConfirmDelete}
      />
      <MDTypography>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Category Details
                </MDTypography>
              </MDBox>
              <MDBox sx={{ m: '2rem' }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <MDTypography variant="h6">Details</MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6} lg={6}>
                            <MDBox mb={2}>
                              <MDInput
                                type="text"
                                label="Name"
                                fullWidth
                                name="name"
                                disabled={type === "VIEW"}
                                value={data?.name}
                                onChange={handleChange}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} md={12} lg={3} mb={3}>
                            <MDBox mb={2}>
                              <MDInput
                                type="color"
                                label="color"
                                fullWidth
                                name="color"
                                disabled={type === "VIEW"}
                                value={data?.color}
                                onChange={handleChange}
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} lg={4} mb={4}>
                          <MDTypography variant="h6" color="black">
                            Icon
                          </MDTypography>
                          <MDBox mb={2}>
                            <img
                              src={file ? `${data.icon}` : data.icon ? `${BACKEND_URL}${data.icon}` : ""}
                              width="50px"
                            />
                          </MDBox>
                          <MDBox mb={2}>
                            <MDInput
                              type="file"
                              fullWidth
                              disabled={type === "VIEW"}
                              onChange={handleFileChange}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={8} lg={8}>
                          <MDBox mb={2}>
                            <MDInput
                              type="text"
                              label="Description"
                              fullWidth
                              disabled={type === "VIEW"}
                              value={data?.description}
                              name={"description"}
                              onChange={handleChange}
                            />
                          </MDBox>
                        </Grid>
                      </MDBox>
                      {id ? (
                        <>
                          <Grid container justifyContent={"space-between"} alignItems={"center"} mt={3}>
                            <MDTypography px={3} variant="h6" color="black">
                              Questions Table
                            </MDTypography>
                            {type !== "VIEW" ? (
                              <MDButton
                                variant="gradient"
                                color="info"
                                onClick={() => navigate(`/categories/edit/${id}/question/add`)}
                              >
                                Add Question
                              </MDButton>
                            ) : null}
                          </Grid>
                          <Divider />
                          <DataTable
                            table={{
                              columns: questionsColumns,
                              rows: data?.questions || [],
                            }}
                            isSorted={true}
                            entriesPerPage={false}
                            showTotalEntries={true}
                            totalCount={data?.questions.length}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            limit={data?.questions.length}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </MDBox>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <MDTypography variant="h6">Focus Area</MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MDBox pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12} lg={12}>
                            <MDBox mb={2} sx={{ width: '100%' }}>
                              <MDInput
                                type="text"
                                label="Message"
                                fullWidth
                                name="lowScoreSuggestion"
                                disabled={type === "VIEW"}
                                value={data?.lowScoreSuggestion}
                                onChange={handleChange}
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </MDBox>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <MDTypography variant="h6">How to improve</MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Tabs orientation={'horizontal'} value={tabValue} onChange={handleSetTabValue}>
                      <Tab
                        label="Our suggestions"
                        sx={{ borderColor: 'primary.main' }}
                        {...a11yProps(0)}
                      />

                      <Tab
                        label="Top 3 services"
                        {...a11yProps(1)}
                      />
                      <Tab
                        label="What does other companies do"
                        {...a11yProps(2)}
                      />
                    </Tabs>
                    <CustomTabPanel value={tabValue} index={0}>
                      {data?.howToImprove?.ourSuggestions?.map((suggestion, index) => {
                        return (
                          <MDBox mt={2}>
                            <Card>
                              <MDTypography>
                                {type !== "VIEW" && index > 0 ? (
                                  <Grid container style={{ justifyContent: "end" }} pr={2} >
                                    <Link
                                      style={{ cursor: "pointer", fontSize: "16px" }}
                                      color={"red"}
                                      onClick={() => {
                                        onDeleteSuggestion(index, 'ourSuggestions');
                                      }}
                                    >
                                      Delete
                                    </Link>
                                  </Grid>
                                ) : null}
                                <Grid container>
                                  <Grid item lg={1} mt={3} sx={{ display: 'flex' }}>
                                    <MDBox ml={3} mt={1}>
                                      <img
                                        src={suggestion.suggestionIcon && typeof suggestion.suggestionIcon !== 'string' ? URL.createObjectURL(suggestion.suggestionIcon) : suggestion.suggestionIcon ? `${BACKEND_URL}${suggestion.suggestionIcon}` : ""}
                                        width="50px"
                                      />
                                    </MDBox>
                                  </Grid>
                                  <Grid item xs={10} md={10} lg={3}>
                                    <MDTypography variant="h6" color="black">
                                      Icon
                                    </MDTypography>
                                    <MDBox>
                                      <MDInput
                                        type="file"
                                        fullWidth
                                        disabled={type === "VIEW"}
                                        onChange={(e) => handleIconChange(e, index)}
                                      />
                                    </MDBox>
                                  </Grid>
                                  <Grid item xs={12} md={8} lg={8} p={2} style={{ marginTop: "10px" }}>
                                    <MDInput
                                      type="text"
                                      label="Title"
                                      fullWidth
                                      name={'title'}
                                      value={suggestion?.title}
                                      disabled={type === "VIEW"}
                                      onChange={(e) => handleHowToImproveChange(e, 'ourSuggestions', index)}
                                    />
                                  </Grid>
                                </Grid>
                              </MDTypography>

                              <Grid item xs={12} md={12} lg={12} p={2}>
                                <MDBox mb={2}>
                                  <MDInput
                                    type="text"
                                    label="Description"
                                    fullWidth
                                    disabled={type === "VIEW"}
                                    value={suggestion?.description}
                                    name={"description"}
                                    onChange={(e) => handleHowToImproveChange(e, 'ourSuggestions', index)}
                                  />
                                </MDBox>
                              </Grid>
                            </Card>
                          </MDBox>
                        )
                      })}
                      {type !== "VIEW" && data?.howToImprove?.ourSuggestions?.length <= 2 ? (
                        <Grid container justifyContent="end">
                          <MDBox mt={4} mb={1}>
                            <MDButton variant="gradient" color="info" onClick={addSuggestions}>
                              Add More
                            </MDButton>
                          </MDBox>
                        </Grid>
                      ) : null}

                    </CustomTabPanel>

                    <CustomTabPanel value={tabValue} index={1}>
                      <Grid item xs={12} md={6} lg={12} >
                        <FormControl sx={{ m: 1, width: "100%" }}>
                          <Autocomplete
                            multiple
                            options={allServices}
                            name="topThreeServices"
                            value={data.howToImprove.topThreeServices}
                            isOptionEqualToValue={(e) => e}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => handleHowToImproveChange(newValue, 'topThreeServices')}
                            renderInput={(params, index) => <TextField key={index} {...params} label="Select Services" />}
                          />
                        </FormControl>
                      </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={2}>
                      <MDBox sx={{ p: 3 }}>
                        {data?.howToImprove?.otherCompanySuggestions?.map((suggestion, index) => {
                          return (
                            <MDBox mt={2}>
                              <Card>
                                <MDTypography>
                                  {type !== "VIEW" && index > 0 ? (
                                    <Grid container style={{ justifyContent: "end" }} pr={2} >
                                      <Link
                                        style={{ cursor: "pointer", fontSize: "16px" }}
                                        color={"red"}
                                        onClick={() => {
                                          onDeleteSuggestion(index, 'otherCompanySuggestions');
                                        }}
                                      >
                                        Delete
                                      </Link>
                                    </Grid>
                                  ) : null}
                                  <Grid container>
                                    <Grid item xs={12} md={4} lg={4} p={2}>
                                      <MDInput
                                        type="text"
                                        label="Title"
                                        fullWidth
                                        name="title"
                                        disabled={type === "VIEW"}
                                        value={suggestion?.title}
                                        onChange={(e) => handleHowToImproveChange(e, 'otherCompanySuggestions', index)}
                                      />
                                    </Grid>

                                    <Grid item xs={12} md={8} lg={8} p={2}>
                                      <MDBox mb={2}>
                                        <MDInput
                                          type="text"
                                          label="Description"
                                          fullWidth
                                          disabled={type === "VIEW"}
                                          value={suggestion?.description}
                                          name={"description"}
                                          onChange={(e) => handleHowToImproveChange(e, 'otherCompanySuggestions', index)}
                                        />
                                      </MDBox>
                                    </Grid>
                                  </Grid>
                                </MDTypography>
                              </Card>
                            </MDBox>
                          )
                        })}
                      </MDBox>
                      {type !== "VIEW" && data?.howToImprove?.otherCompanySuggestions?.length <= 2 ? (
                        <Grid container justifyContent="end">
                          <MDBox mt={4} mb={1}>
                            <MDButton variant="gradient" color="info" onClick={addOtherCompanySuggestions}>
                              Add More
                            </MDButton>
                          </MDBox>
                        </Grid>
                      ) : null}
                    </CustomTabPanel>
                  </AccordionDetails>
                </Accordion>
                <Grid container spacing={0} justifyContent="flex-end">
                  {type !== "VIEW" ? (
                    <MDBox mt={4} mb={1}>
                      <MDButton variant="gradient" color="info" onClick={onSubmit}>
                        {type === "ADD" ? "Save" : "Update"}
                      </MDButton>
                    </MDBox>
                  ) : null}
                  <MDBox mt={4} mb={1} ml={1}>
                    <MDButton variant="outlined" color="info" onClick={() => navigate(-1)}>
                      Cancel
                    </MDButton>
                  </MDBox>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDTypography >

    </>
  );
};

export default CategoryForm;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MDBox sx={{ p: 3 }}>
          <MDTypography>{children}</MDTypography>
        </MDBox>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
