import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React, { useEffect } from "react";
import UserContainer from "./UserContainer";

const UserForm = ({ type }) => {
  const { data, handleChange, loading, onSubmit, setFormType, navigate } = UserContainer();

  useEffect(() => {
    setFormType(type);
  }, [type, setFormType]);

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Users Details
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="First Name"
                        fullWidth
                        name="firstName"
                        disabled={true}
                        // disabled={type === "VIEW"}
                        value={data?.firstName}
                      />
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="Last Name"
                        fullWidth
                        // disabled={type === "VIEW"}
                        disabled={true}
                        value={data?.lastName}
                      />
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={2}>
                      <MDInput
                        type="email"
                        label="Email"
                        fullWidth
                        // disabled={type === "VIEW"}
                        disabled={true}
                        value={data?.email}
                      />
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="Company Name"
                        fullWidth
                        // disabled={type === "VIEW"}
                        disabled={true}
                        value={data?.companyName}
                      />
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={2}>
                      <MDInput
                        type="number"
                        name={"numberOfEmployees"}
                        label="No. of Employees"
                        fullWidth
                        disabled={type === "VIEW"}
                        value={data?.numberOfEmployees}
                        onChange={handleChange}
                      />
                    </MDBox>
                  </Grid>

                  {/* <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={2}>
                      <MDInput
                        type="number"
                        name={"numberOfSurveys"}
                        label="No. of Surveys"
                        fullWidth
                        disabled={type === "VIEW"}
                        value={data?.numberOfSurveys}
                        onChange={handleChange}
                      />
                    </MDBox>
                  </Grid> */}
                </Grid>

                <Grid container spacing={0} justifyContent="flex-end">
                  {type === "EDIT" ? (
                    <MDBox mt={4} mb={1}>
                      <MDButton variant="gradient" color="info" onClick={onSubmit}>
                        Update
                      </MDButton>
                    </MDBox>
                  ) : null}
                  <MDBox mt={4} mb={1} ml={1}>
                    <MDButton variant="outlined" color="info" onClick={() => navigate(-1)}>
                      Cancel
                    </MDButton>
                  </MDBox>
                </Grid>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default UserForm;
