import { Card, Grid, Icon, Link } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React, { useEffect } from "react";
import QuestionContainer from "./QuestionsContainer";

const UserForm = ({ type }) => {
  const {
    data,
    handleChange,
    loading,
    onSubmit,
    setFormType,
    navigate,
    onAddOption,
    onDeleteOption,
  } = QuestionContainer();

  useEffect(() => {
    setFormType(type);
  }, [type, setFormType]);

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                {type !== "ADD" ? "Question Details" : "Add Question"}
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        key={"question"}
                        label={"Question"}
                        fullWidth
                        disabled={type === "VIEW"}
                        value={data?.question}
                        name="question"
                        onChange={handleChange}
                      />
                    </MDBox>
                  </Grid>

                  {data?.choices.map((choice, index) => {
                    return (
                      <Grid item xs={12} md={4} lg={3} alignItems="center">
                        <MDBox mb={2}>
                          <MDInput
                            mb={2}
                            type="text"
                            key={choice._id}
                            label={`Option ${index + 1}`}
                            fullWidth
                            disabled={type === "VIEW"}
                            value={choice?.name}
                            name="name"
                            onChange={(e) => handleChange(e, index)}
                          />
                          <MDInput
                            type="text"
                            key={index}
                            label={`Value ${index + 1} (%)`}
                            fullWidth
                            disabled={type === "VIEW"}
                            value={choice?.value}
                            name="value"
                            style={{ marginTop: "10px" }}
                            onChange={(e) => handleChange(e, index)}
                          />
                          {type !== "VIEW" ? (
                            <Link
                              fontSize={12}
                              style={{ cursor: "pointer" }}
                              color={"red"}
                              onClick={() => {
                                onDeleteOption(index);
                              }}
                            >
                              Delete
                            </Link>
                          ) : null}
                        </MDBox>
                      </Grid>
                    );
                  })}
                </Grid>

                {type !== "VIEW" ? (
                  <Grid container justifyContent="end">
                    <MDBox mt={4} mb={1}>
                      <MDButton variant="gradient" color="info" onClick={onAddOption}>
                        Add Option
                      </MDButton>
                    </MDBox>
                  </Grid>
                ) : null}

                <Grid container spacing={0} justifyContent="flex-end">
                  {type !== "VIEW" ? (
                    <MDBox mt={4} mb={1}>
                      <MDButton variant="gradient" color="info" onClick={onSubmit}>
                        {type === "ADD" ? "Save" : "Update"}
                      </MDButton>
                    </MDBox>
                  ) : null}
                  <MDBox mt={4} mb={1} ml={1}>
                    <MDButton variant="outlined" color="info" onClick={() => navigate(-1)}>
                      Cancel
                    </MDButton>
                  </MDBox>
                </Grid>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default UserForm;
