/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useDebounce from "utils/hooks";
import { getUsers } from "redux/actions/userAction";
import EditIcon from '@mui/icons-material/Edit';
import { Icon, Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "components/MDDialog/ConfirmationDialog";
import { downloadExcel, updateUserStatus } from "redux/actions/userAction";

function Users() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState(10);

  const searchKeys = ["fullName", "email"];
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useDebounce(500, "");

  const [filterKey, setFilterKey] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const deleteDialogText = "Are you sure ypu want to update this user?";
  const [rowData, setRowData] = useState({});

  useEffect(() => {
    dispatch(getUsers(setLoading, currentPage, rows, searchKeys, search, filterKey, filterData));
  }, [dispatch, currentPage, rows, searchText, filterData]);

  let { allUsers } = useSelector((state) => state.users);

  const columns = [
    { Header: "Company Name", accessor: "companyName", align: "left" },
    { Header: "email", accessor: "email", align: "left" },
    { Header: "Number of Employees", accessor: "numberOfEmployees", align: "center" },
    // { Header: "Number of Sruveys", accessor: "numberOfSurveys", align: "center" },
    {
      Header: "Status", accessor: "activeToggle", align: "center", Cell: props => {
        return (
          <>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={props.row.original.isActive} onClick={() => { setRowData(props.row.original); setShowConfirm(true); }} />
            </MDBox>
            <MDTypography
              variant="button"
              fontWeight="regular"
              alignItems="center"
              color="text"
            >
              {props.row.original.isActive ? "Active" : "Inactive"}
            </MDTypography>
          </>
        )
      }
    },
    {
      Header: "action", accessor: "action", align: "center", Cell: props => {
        return (
          <><Icon sx={{ fontWeight: "bold", cursor: "pointer", marginX: "5px" }} onClick={() => navigate(`/users/view/${props.row.original._id}`)}>visibility</Icon>
            <Icon
              sx={{ fontWeight: "bold", cursor: "pointer", marginX: "5px" }}
              onClick={() => navigate(`/users/edit/${props.row.original._id}`)}
            >
              edit
            </Icon>
            <Icon sx={{ fontWeight: "bold", cursor: "pointer" }} onClick={() => {
              console.log(props.row)
              dispatch(downloadExcel(props.row.original._id, props.row.original.companyName))
            }}>assessment</Icon>
          </>
        )
      }
    },
  ];

  const onConfirmDelete = () => {
    dispatch(updateUserStatus(rowData._id, { deactivate: !rowData.isActive }, setShowConfirm, currentPage, rows, searchKeys, search, filterKey, filterData));
  }

  return (
    <>
      <ConfirmDialog open={showConfirm} setOpen={setShowConfirm} dialogText={deleteDialogText} handleAgree={onConfirmDelete} />

      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Users Table
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows: allUsers.users }}
                    isSorted={true}
                    entriesPerPage={false}
                    showTotalEntries={true}
                    totalCount={allUsers.count}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    limit={rows} />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default Users;
