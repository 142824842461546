import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import React from "react";
import QuestionForm from "./QuestionForm";

export default function ViewQuestion() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <QuestionForm type="VIEW" />
      </MDBox>
    </DashboardLayout>
  );
}
