import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const DropDown = ({ onChange, value, options, label, name, disabled }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        name={name}
        label={label}
        onChange={onChange}
        style={{ paddingTop: "12px", paddingBottom: "12px" }}
        placeholder="Select"
        disabled={disabled}
      >
        {options?.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDown;
