import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";

export default function ConfirmDialog({ open, setOpen, dialogText, handleAgree }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        p={4}
      >
        <DialogTitle id="alert-dialog-title">{dialogText}</DialogTitle>
        {/* <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                       {dialogText}
                    </DialogContentText>
                </DialogContent> */}
        <DialogActions>
          <MDButton variant="text" color="error" onClick={handleClose} mr={0}>
            cancel
          </MDButton>
          <MDButton variant="text" color="info" onClick={handleAgree} ml={0}>
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
