import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";

const getUsers = (setLoading, page, limit, searchKeys, search, filterKey, filterData) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }
    const res = await api("get", `${Constants.END_POINT.USERS}?page=${page}&limit=${limit}&searchKeys=${JSON.stringify(searchKeys)}&searchString=${search}&filterKey=${filterKey}&filterData=${JSON.stringify(filterData)}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.USERS,
                payload: res.data,
                count: res.count
            });
        }
    } else {
        dispatch({
            type: types.USERS,
            payload: [],
            count: 0
        });
    }
    if (setLoading) {
        setLoading(false);
    }
};

const UserEnableDisableAction = (id, deactivate) => async (dispatch) => {
    const res = await api("put", Constants.END_POINT.ENABLE_DISABLE_USER + id, deactivate);
    if (res.success) {
        dispatch(getUsers());
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};

const getUser = (id, returnData) => async (dispatch) => {
    const res = await api("get", Constants.END_POINT.USER + id);
    if (res.success) {
        if (res.data) {
            if (returnData) {
                returnData(res.data);
            }
        }
    }
};

const editUser = (id, payload, navigate) => async (dispatch) => {
    const res = await api("put", Constants.END_POINT.USERS + id, payload);
    if (res.success) {
        navigate(-1);
    }
};

const updateUserStatus = (id, deactivate, next, page, limit, searchKeys, search, filterKey, filterData) => async (dispatch) => {

    const payload = {
        ...deactivate,
    };

    const res = await api("put", Constants.END_POINT.ENABLE_DISABLE_USER + id, payload);
    if (res.success) {
        dispatch(getUsers(null, page, limit, searchKeys, search, filterKey, filterData));
        next(false);
    }
};

const downloadExcel = (id, companyName) => async () => {
    console.log("Download Excel: id", id);
    try {
        const res = await api("get", Constants.END_POINT.SURVEY_EXCEL + id);
        if (res.success && res.data) {
            // Assume res.data is the CSV string
            const blob = new Blob([res.data], { type: 'text/csv;charset=utf-8;' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${companyName.replace(/\s+/g, '').trim().toLowerCase()}-usersurvey.csv`); // You can specify the filename here
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl); // Clean up the URL object
        } else {
            console.log('Failed to download the file.');
        }
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
};

export { getUsers, getUser, UserEnableDisableAction, editUser, updateUserStatus, downloadExcel };
