import { types } from "../types/types";
const intitalState = {
    allUsers: {
        users: [],
        count: 0
    },
    usersByYear: []
};

const usersReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.USERS:
            return {
                ...state,
                allUsers: { users: action.payload, count: action.count },

            };
        case types.USERS_BY_YEAR:
            return {
                ...state,
                usersByYear: action.payload,
            };
        case types.RESET_STATE:
            return {
                ...state,
                usersByYear: []
            }
        default:
            return { ...state };
    }
};

export default usersReducer;
