import { types } from "../types/types";
const intitalState = {
  allCategories: {
    categories: [],
    count: 0,
  },
};

const categoriesReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.CATEGORIES:
      return {
        ...state,
        allCategories: { categories: action.payload, count: action.count },
      };
    // case types.USERS_BY_YEAR:
    //     return {
    //         ...state,
    //         usersByYear: action.payload,
    //     };
    // case types.RESET_STATE:
    //     return {
    //         ...state,
    //         usersByYear: []
    //     }
    default:
      return { ...state };
  }
};

export default categoriesReducer;
